




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import {
  CHART_CLICK_EVENT, ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE,
} from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { Dictionary } from 'vue-router/types/router';
import proceduresModule from '@/store/modules/proceduresModule';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV2: () => getComponent('tasqs/SetpointFeedbackInfoV2'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class TasqFeedback extends mixins(DataLoading) {


  // Validate
  selectionAction: any = '';
  // Systems
  systems: string[] = []
  // Symptoms
  symptoms: string[] = []
  // Causes
  causes: string[] = []
  // Actions
  actions: string[] = []

  systemsComment: string = ''
  symptomsComment: string = ''
  causesComment: string = ''
  actionsComment: string = ''


  systemOptionsSelected = []
  symptomOptionsSelected = []
  causeOptionsSelected = []
  actionOptionsSelected = []


  isSavingProcedureStep = false


  isSearchingProcedureOptions = false

  searchingProcedureText: string = ''

  showingCommentIDs: string[] = []


  setDropdownArrowIconCommentResponse(comment_id) {

	  if (this.showingCommentIDs.includes(comment_id)) {
		const index = this.showingCommentIDs.indexOf(comment_id);
		if (index > -1) {
			this.showingCommentIDs.splice(index, 1);
		}
	  } else {
		  this.showingCommentIDs.push(comment_id)
	  }
  }


  getDropdownArrowIconCommentResponseEnabled(comment_id) {
	  if (this.showingCommentIDs.includes(comment_id)) {
		  return true
	  }
	  return false
  }
  

  getDropdownArrowIconCommentResponse(comment_id) {
	  if (this.showingCommentIDs.includes(comment_id)) {
		  return "Hide response"
	  }
	  return "See response"
  }
  
 
							


  getCommentSystem(comment) {
	  var response = ''
	  for (var x = 0; x < comment.ResponseData.Systems.length; x++) {
		  var system_string = comment.ResponseData.Systems[x]
		  var system_strings = system_string.split("<>")
		  if (system_strings[0] == "null") {
			  system_string = system_strings[1]
		  } else {
			  system_string = system_strings[0] + ' ' + system_strings[1]
		  }
		  
		  if (x > 0) {
			  response = response + ", " + system_string
		  } else {
			  response = response + system_string
		  } 
	  }
	  return response
  }

  getCommentSymptoms(comment) {
	  var response = ''
	  for (var x = 0; x < comment.ResponseData.Symptoms.length; x++) {
		  var symptom_string = comment.ResponseData.Symptoms[x]
		  var symptom_strings = symptom_string.split("<>")
		  if (symptom_strings[0] == "null") {
			  symptom_string = symptom_strings[1]
		  } else {
			  symptom_string = symptom_strings[0] + ' ' + symptom_strings[1]
		  }
		  if (x > 0) {
			  response = response + ", " + symptom_string
		  } else {
			  response = response + symptom_string
		  } 
	  }
	  return response
  }

  getCommentCauses(comment) {
	  var response = ''
	  for (var x = 0; x < comment.ResponseData.Causes.length; x++) {
		  var cause_string = comment.ResponseData.Causes[x]
		  var cause_strings = cause_string.split("<>")
		  if (cause_strings[0] == "null") {
			  cause_string = cause_strings[1]
		  } else {
			  cause_string = cause_strings[0] + ' ' + cause_strings[1]
		  }
		  if (x > 0) {
			  response = response + ", " + cause_string
		  } else {
			  response = response + cause_string
		  } 
	  }
	  return response
  }


  getCommentActions(comment) {
	  var response = ''
	  for (var x = 0; x < comment.ResponseData.Actions.length; x++) {
		  var action_string = comment.ResponseData.Actions[x]
		  var action_strings = action_string.split("<>")
		  if (action_strings[0] == "null") {
			  action_string = action_strings[1]
		  } else {
			  action_string = action_strings[0] + ' ' + action_strings[1]
		  }
		  if (x > 0) {
			  response = response + ", " + action_string
		  } else {
			  response = response + action_string
		  } 
	  }
	  return response
  }



  get emptyArticleSectionText() {
	  if (this.stepper == 1) {
		  return 'No articles available'
	  } else if (this.stepper == 2) {
		  return 'No system articles available'
	  } else if (this.stepper == 3) {
		  return 'No symptom articles available'
	  } else if (this.stepper == 4) {
		  return 'No cause articles available'
	  } else if (this.stepper == 5) {
		  return 'No action articles available'
	  }
	  return ''
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  formatBubbleText(val) {
	  return this.toTitleCase(val.toLowerCase())
  }


  filterBubbleValue(val) {

	if (this.searchingProcedureText !== '' && this.isSearchingProcedureOptions) {
		if (val.toLowerCase().startsWith(this.searchingProcedureText.toLowerCase())) {
			return false
		}
		return true
	}
	if (val == '') {
		return true
	}
	return false
	
  }

  handleInput(e) {
	  console.log(this.systemOptionsSelected)
	  if (this.searchingProcedureText != '') {

	  }

  }



  get articlesForSelectedBubbles() {
	  var results = []
	  if (this.stepper == 2) {
		  for (var x = 0; x < this.systemOptionsSelected.length; x++) {
			  // @ts-ignore
			  for (var y = 0; y < this.systemOptionsSelected[x].systems.length; y++) {
				  // @ts-ignore
				  var system = this.systemOptionsSelected[x].systems[y]
				  var check_string = system.title + "<>" + system.text
				  if (this.systems.includes(check_string) && (system.description != null || system.action != null)) {
					  // @ts-ignore
					  results.push(system)
				  }
			  }
		  }
	  } else if (this.stepper == 3) {
		  for (var x = 0; x < this.symptomOptionsSelected.length; x++) {
			  // @ts-ignore
			  for (var y = 0; y < this.symptomOptionsSelected[x].symptoms.length; y++) {
				  // @ts-ignore
				  var symptom = this.symptomOptionsSelected[x].symptoms[y]
				  var check_string = symptom.title + "<>" + symptom.text
				  if (this.symptoms.includes(check_string) && (symptom.description != null || symptom.action != null)) {
					  // @ts-ignore
					  results.push(symptom)
				  }
			  }
		  } 
	  } else if (this.stepper == 4) {
		  for (var x = 0; x < this.causeOptionsSelected.length; x++) {
			  // @ts-ignore
			  for (var y = 0; y < this.causeOptionsSelected[x].causes.length; y++) {
				  // @ts-ignore
				  var cause = this.causeOptionsSelected[x].causes[y]
				  var check_string = cause.title + "<>" + cause.text
				  if (this.systems.includes(check_string) && (cause.description != null || cause.action != null)) {
					  // @ts-ignore
					  results.push(cause)
				  }
			  }
		  } 
	  } else if (this.stepper == 5) {
		  for (var x = 0; x < this.actionOptionsSelected.length; x++) {
			  // @ts-ignore
			  for (var y = 0; y < this.actionOptionsSelected[x].actions.length; y++) {
				  // @ts-ignore
				  var action = this.actionOptionsSelected[x].actions[y]
				  var check_string = action.title + "<>" + action.text
				  if (this.actions.includes(check_string) && (action.description != null || action.action != null)) {
					  // @ts-ignore
					  results.push(action)
				  }
			  }
		  } 
	  }
	  return results
  }


  systemIsSelected(system) {
	  var check_string = system.text
	  check_string = system.title + "<>" + system.text
	  if (this.systems.includes(check_string)) {
		  return true
	  }
	  return false
  }

  selectedSystem(system) {
	  var check_string = system.text
	  check_string = system.title + "<>" + system.text

	  if (this.systems.includes(check_string)) {
		const index = this.systems.indexOf(check_string);
		if (index > -1) {
			this.systems.splice(index, 1);
		}
	  } else {
		  this.systems.push(check_string)
	  }
  }





  symptomIsSelected(symptom) {
	  var check_string = symptom.text
	  check_string = symptom.title + "<>" + symptom.text
	  if (this.symptoms.includes(check_string)) {
		  return true
	  }
	  return false
  }

  selectedSymptom(symptom) {
	  var check_string = symptom.text
	  check_string = symptom.title + "<>" + symptom.text
	  if (this.symptoms.includes(check_string)) {
		const index = this.symptoms.indexOf(check_string);
		if (index > -1) {
			this.symptoms.splice(index, 1);
		}
	  } else {
		  this.symptoms.push(check_string)
	  }
  }
  

  causeIsSelected(cause) {
	  var check_string = cause.text
	  check_string = cause.title + "<>" + cause.text
	  if (this.causes.includes(check_string)) {
		  return true
	  }
	  return false
  }

  selectedCause(cause) {
	  var check_string = cause.text
	  check_string = cause.title + "<>" + cause.text
	  if (this.causes.includes(check_string)) {
		const index = this.causes.indexOf(check_string);
		if (index > -1) {
			this.causes.splice(index, 1);
		}
	  } else {
		  this.causes.push(check_string)
	  }
  }

  

  actionIsSelected(action) {
	  var check_string = action.text
	  check_string = action.title + "<>" + action.text
	  if (this.actions.includes(check_string)) {
		  return true
	  }
	  return false
  }

  selectedAction(action) {
	  var check_string = action.text
	  check_string = action.title + "<>" + action.text
	  if (this.actions.includes(check_string)) {
		const index = this.actions.indexOf(check_string);
		if (index > -1) {
			this.actions.splice(index, 1);
		}
	  } else {
		  this.actions.push(check_string)
	  }
  }



  // Version of json s3 file
  get jsonVersion() {
	  return proceduresModule.proceduresJsonVersion
  }

  setpointDetailsWellHistory = null

  showSetpointWellHistoryDetailsPopup = false

  chartLoaded = false;

  defermentLabelingModal = false;

  isLoadingSetpointData = false;

  showJobDetailsPopup = false;

  stepper = 1;



  stepOneVariant = '';

  datetime = '';

  selectionActions = [
    {
      text: 'Yes',
      value: 'YES',
	  style: 'FULL'
    },
    {
      text: 'No',
      value: 'NO',
	  style: 'FULL'
    },
    {
      text: 'Not sure',
      value: 'DONT_KNOW',
	  style: 'SUB'
    },
  ];

  showComment = false;

  showActionComment = false;

  predictionAdditionalAction = '';

  setpointComment = '';

  comment = '';

  actionComment = '';

  standardReasons = getConfigEnv('FEEDBACK_REASONS');

  reason = [];

  stepTwoVariant = '';

  jobAction = [];

  jobActions = [
    'Changed setpoint',
    'On site fix',
    'Change setpoint',
    'Troubleshooting',
    'No action needed',
    'Other',
  ];

  stepThreeVariant = '';

  foundProcedure = null;

  activityShown = 'history';

  activeEvent = '';

  submittingSetpointData = false;



  feedbackSteps = [
    {
      id: 1,
      number: 1,
      name: 'Validate',
      active: true,
    },
    {
      id: 2,
      number: 2,
      name: 'System',
      active: false,
    },
    {
      id: 3,
      number: 3,
      name: 'Symptom',
      active: false,
    },
    {
      id: 4,
      number: 4,
      name: 'Cause',
      active: false,
    },
    {
      id: 5,
      number: 5,
      name: 'Action',
      active: false,
    },
    // {
    //   id: 4,
    //   number: 4,
    //   name: 'Resolution',
    //   active: false,
    // },
  ];


  windowWidth = window.innerWidth;

  get isOnMobile() {
	  return this.windowWidth < 769
  }




  @Watch('reason')
  updateReasonValue(value) {
    // @ts-ignore
    if (value.find((j) => j.includes('Other'))) {
      // @ts-ignore
      this.showComment = true;
    }
  }

  get setpointNameRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('Afterflow timer close');
	  results.push('Flowrate low time limit');
	  results.push('Closed timer');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        results.push(mapSetpointNames[key]);
      }
	  }
	  return results;
  }

  get setpointEstimatedCurrentRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('-');
	  results.push('-');
	  results.push('-');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }

  get setpointRecommendedRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };
	  results.push('Disable');
	  results.push('0.5');
	  results.push('Disabled');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Optimal Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }



  closeBatchResponseFeedbackSidebar() {
	  this.$emit('close-editing');
  }

  showWellHistorySetpointDetails(data) {
	  this.showSetpointWellHistoryDetailsPopup = true;
	  this.setpointDetailsWellHistory = data;
  }

  closeSetpointWellHistoryModal() {
	  this.showSetpointWellHistoryDetailsPopup = false;
	  this.setpointDetailsWellHistory = null;
  }

  get getUplift(): any {
    try {
      const rounded_value = setpointV2Module.uplift.toFixed(0);
      return rounded_value;
    } catch (err) {
      return setpointV2Module.uplift;
    }
  }

  get getDefermentUnits(): any {
    return setpointV2Module.unitType;
  }

  async acceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'YES';
    // @ts-ignore
    this.reason = ['Setpoint Accepted'];

    // tasqFeedbackModule.leaveFeedbackV2({
    //   tasq: this.tasq,
    //   selection: 'YES',
	//   comment: this.comment,
	//   markAsComplete: false,
	//   shouldPostJobComment: this.comment != '',
	//   shouldPostAsGlobalWellComment: true,
    // });

    const setpointData = setpointV2Module.tasqSetpointData;
    setpointData.Uplift = `${this.getUplift} ${this.getDefermentUnits}`;
    tasqFeedbackModule.postComment({
      tasq: this.tasq,
      selection: 'YES',
	  setpointData,
    });

    const date = new Date();
    await tasqActionsModule.updateTasqStatus({
      reason: 'Setpoint accepted, waiting 7 days for results',
      date: date.setDate(date.getDate() + 7),
    });

    const successText = 'Setpoint accepted!';
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if(tasqsListModule.activePage === 'Kanban'){
       this.$router.push({ name: 'TasqsKanban' });
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async dismissSetpointForNow() {
    let successText = 'Success'!;
    this.submittingSetpointData = true;
    const date = new Date();
    successText = 'Successfully added to waitlist!';
    await tasqActionsModule.updateTasqStatus({
      reason: 'Setpoint dismissed for 7 days',
      date: date.setDate(date.getDate() + 7),
    });

    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;

    if(tasqsListModule.activePage === 'Kanban'){
       this.$router.push({ name: 'TasqsKanban' });
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    //   this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');

    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async doNotAcceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'NO';
    // @ts-ignore
    this.reason = ['Setpoint rejected'];
    // await this.leaveFeedbackV2(true, true, true);

    // tasqFeedbackModule.leaveFeedbackV2({
    //   tasq: this.tasq,
    //   selection: 'NO',
	//   comment: this.comment,
	//   reason: this.reason,
	//   correctedStateChangeDate: '',
	//   jobAction: [],
	//   markAsComplete: true,
	//   shouldPostJobComment: this.comment != '',
	//   shouldPostAsGlobalWellComment: true,
	//   completedForToday: true,
    // });

    const successText = 'Setpoint rejected';
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if(tasqsListModule.activePage === 'Kanban'){
       this.$router.push({ name: 'TasqsKanban' });
    }else if(tasqsListModule.activePage === 'Operations'){
      this.$router.push({ name: 'Operations' });
    }else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
    // this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  closeSetpointModal() {
	  this.showJobDetailsPopup = false;
  }

  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
		  {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: '2021-08-21T13:35',
          borderColor: 'rgba(255,255,255,0.7)',
          borderDash: [9, 7],
          // label: {
          //   content: "TODAY",
          //   enabled: true,
          //   position: "top"
          // }
		  },
	  ],
    },
  }

  chartCurrentData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartCurrentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  chartBestData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartBestOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#FFFFFF',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  showSetpointDataFromHistory(setpointData) {
  }

  mainTitleValue() {
    // if (!this.tasq.completed) {
    return 'What action was taken?';
    // } else {
    // 	return "This tasq was completed"
    // }
  }

  get currentWellType(): any {
    if(assetsModule.activeTasq){
      return 'producing'
    }
	  return this.$route.query.type;
  }

  showSetpointAnalysisView() {
	  this.showJobDetailsPopup = true;
  }

  getWellCommentResponseIcon(comment) {
	  if (comment.ResponseData.UserResponse != null) {
		if (comment.ResponseData.UserResponse.Selection == 'YES') {
			return 'task_alt';
		} if (comment.ResponseData.UserResponse.Selection == 'NO') {
			return 'arrow_downward';
		} if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
			return 'quiz';
		} if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
			return 'quiz';
		} if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
			return 'smart_toy';
		}
	  } else {
			if (comment.ResponseData.Validate == 'YES') {
				return 'task_alt';
			} if (comment.ResponseData.Validate == 'NO') {
				return 'arrow_downward';
			} if (comment.ResponseData.Validate == 'NOT_SURE') {
				return 'quiz';
			} if (comment.Validate == 'NOT_SURE') {
				return 'quiz';
			} if (comment.Validate == 'AUTO') {
				return 'smart_toy';
			}
	  }

  }

  getReadableWellHistoryComment(comment) {
    var readableComment = 'Confirmed';
	if (comment.ResponseData.UserResponse != null) {
		if (comment.ResponseData.UserResponse.Selection == 'YES') {
			var readableComment = 'Confirmed';
		} else if (comment.ResponseData.UserResponse.Selection == 'NO') {
			var readableComment = 'No';
		} else if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
			var readableComment = 'Not sure of';
		} else if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
			var readableComment = 'Closed automatically';
		}

		if (comment.ResponseData.UserResponse.Selection != 'AUTO' && (comment.JobType.toLowerCase() == 'anomaly' || comment.JobType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
			readableComment += ' - Anomaly';
		} else if (comment.JobType.toLowerCase() == 'off-target' || this.tasq.predictionType.toLowerCase() == 'off-target') {
			readableComment += ' - Off-Target';
		} else if (comment.JobType.toLowerCase() == 'off-target rt' || this.tasq.predictionType.toLowerCase() == 'off-target rt') {
			readableComment += ' - Off-Target RT';
		} else if (comment.JobType.toLowerCase() == 'setpoint') {
			readableComment += ' - Setpoint';
		}

		if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
			readableComment += ': ';
			for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
				readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
			}
		}
	} else {
		if (comment.ResponseData.Validate == 'YES') {
			var readableComment = 'Confirmed';
		} else if (comment.ResponseData.Validate == 'NO') {
			var readableComment = 'No';
		} else if (comment.ResponseData.Validate == 'NOT_SURE') {
			var readableComment = 'Not sure of';
		} else if (comment.ResponseData.Validate == 'AUTO') {
			var readableComment = 'Closed automatically';
		}

		// if (comment.ResponseData.UserResponse.Selection != 'AUTO' && (comment.JobType.toLowerCase() == 'anomaly' || comment.JobType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
		// 	readableComment += ' - Anomaly';
		// } else if (comment.JobType.toLowerCase() == 'off-target' || this.tasq.predictionType.toLowerCase() == 'off-target') {
		// 	readableComment += ' - Off-Target';
		// } else if (comment.JobType.toLowerCase() == 'off-target rt' || this.tasq.predictionType.toLowerCase() == 'off-target rt') {
		// 	readableComment += ' - Off-Target RT';
		// } else if (comment.JobType.toLowerCase() == 'setpoint') {
		// 	readableComment += ' - Setpoint';
		// }

		// if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
		// 	readableComment += ': ';
		// 	for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
		// 		readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
		// 	}
		// }
	}

    return readableComment;
  }

  get tasq() {
	  if (tasqsListModule.isBatchResponding) {
      if(!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.currentWellType == 'producing') {
      return assetsModule.activeTasq as TasqJob;
    }
    return tasqsListModule.activeTasq as TasqJob;
  }

  getFormattedDatetime() {
    if (!this.datetime) return '';
    return DateTime.fromISO(this.datetime).toFormat('hha, LLL d, y');
  }

  get tasqResponseData() {
    if (workflowModule.v2ResponseData) {
      return workflowModule.v2ResponseData;
    }
    return null;
  }

  get nextButtonTitle() {
    // if (this.tasq.isManuallyAdded) {
    //   return 'Finish';
    // }
    return 'Continue';
  }

  get identifyingSubText() {
    if (this.tasq.isManuallyAdded) {
      return `Has this ${this.tasq.predictionType} job been resolved?`;
    }
    return '';
  }

  get identifyingText() {
    // if (this.tasq.completed) return 'This tasq has been closed'
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'What action was taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
    if (this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${this.tasq?.engineerType}?`;
  }

  get activeActionText() {
    let text = '';
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || (this.tasq.engineerType === this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
    ) {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select type of change:';
      }
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_FAILURE_TYPE')) {
      if (this.selectionAction === this.selectionActions[1].value) {
        text = 'Please select the reason:';
      }
    }
    if (this.tasq.engineerType === 'issue') {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select the reason:';
      }
    }
    return text;
  }

  get possibleReasons() {
    let reasons = this.standardReasons;
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')
      || this.tasq.engineerType === 'issue'
      || (this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
      || (this.$getConst('TASQ_OFF_TARGET_TYPE') && this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE'))
    ) {
      reasons = this.standardReasons;
      if (this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
        reasons.push('Data issue');
        reasons.push('Downhole solids');
      }
    }

    reasons = [...new Set(reasons)];
    reasons = reasons.sort();
    if (!reasons.includes('Other')) {
      reasons.push('Other');
    }
    return reasons;
  }

  get v2ResponseData() {
	  return workflowModule.v2ResponseData
  }

  get events() {
    return tasqsListModule.tasqEventHistoryList;
    // return tasqProductionDataChartModule.wellHistory;
  }

  get getWellHistory() {
    return workflowModule.wellHistory;
  }

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding
  }



  async created() {

	  

	  if (tasqsListModule.isBatchResponding) {
		  tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0])
		  return tasqsListModule.activeTasq as TasqJob;
	  }

    // workflowModule.getWellHistory(this.tasq.wellName)
    this.$eventBus.$on(CHART_CLICK_EVENT, (date) => {
      if (this.stepper === 4) {
        this.datetime = date;
      }
    });


    if (this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.setupSetpointPage();
    } else {
		this.setupPage()
	}

	

  }


  setupPage() {

	  if (workflowModule.v2ResponseData != null && workflowModule.v2ResponseData.systems != null) {
		  this.selectionAction = workflowModule.v2ResponseData.validate
		  this.systems = workflowModule.v2ResponseData.systems.slice()
		  this.symptoms = workflowModule.v2ResponseData.symptoms.slice()
		  this.causes = workflowModule.v2ResponseData.causes.slice()
		  this.actions = workflowModule.v2ResponseData.actions.slice()

		  this.systemsComment = workflowModule.v2ResponseData.systemsComment == null ? '' : workflowModule.v2ResponseData.systemsComment
		  this.symptomsComment = workflowModule.v2ResponseData.symptomsComment == null ? '' : workflowModule.v2ResponseData.symptomsComment
		  this.causesComment = workflowModule.v2ResponseData.causesComment == null ? '' : workflowModule.v2ResponseData.causesComment
		  this.actionsComment = workflowModule.v2ResponseData.actionsComment == null ? '' : workflowModule.v2ResponseData.actionsComment



		  if (workflowModule.v2ResponseData.validate != null && workflowModule.v2ResponseData.systems.length > 0 && workflowModule.v2ResponseData.symptoms.length > 0 && workflowModule.v2ResponseData.causes.length > 0) {
			  this.stepper = 5
			  this.feedbackSteps[4].active = true
			  this.feedbackSteps[3].active = true
			  this.feedbackSteps[2].active = true
			  this.feedbackSteps[1].active = true
		  } else if (workflowModule.v2ResponseData.validate != null && workflowModule.v2ResponseData.systems.length > 0 && workflowModule.v2ResponseData.symptoms.length > 0) {
			  this.stepper = 4
			  this.feedbackSteps[3].active = true
			  this.feedbackSteps[2].active = true
			  this.feedbackSteps[1].active = true
		  } else if (workflowModule.v2ResponseData.validate != null && workflowModule.v2ResponseData.systems.length > 0) {
			  this.stepper = 3
			  this.feedbackSteps[2].active = true
			  this.feedbackSteps[1].active = true
		  } else if (workflowModule.v2ResponseData.validate != null) {
			  this.feedbackSteps[1].active = true
			  this.stepper = 2
		  } else {
			  this.stepper = 1
		  }

	  } else {
		  this.stepper = 1
	  }



	this.systemOptionsSelected = []
	var systemGroupedByTitle = {}
	this.symptomOptionsSelected = []
	var symptomsGroupedByTitle = {}
	this.causeOptionsSelected = []
	var causesGroupedByTitle = {}
	this.actionOptionsSelected = []
	var actionsGroupedByTitle = {}
	var selectedSystemIndexes: any[] = []
	for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
		
		// Systems
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Systems.length; y++) {
			if (this.systems.includes(proceduresModule.proceduresStepsV2[x].Systems[y].text)) {
				selectedSystemIndexes.push(x)
			}
			
			if (systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title] == null) {
				systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title] = [proceduresModule.proceduresStepsV2[x].Systems[y]]
			} else {
				systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title].push(proceduresModule.proceduresStepsV2[x].Systems[y])
			}
		}
	}

	for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
		var selectedSymptomIndexes: any[] = []
		// Symptoms
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Symptoms.length; y++) {
			if (selectedSystemIndexes.includes(x) || selectedSystemIndexes.length == 0) {
				
				if (this.symptoms.includes(proceduresModule.proceduresStepsV2[x].Symptoms[y].text)) {
					selectedSymptomIndexes.push(x)
				}
				
				if (symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title] == null) {
					symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title] = [proceduresModule.proceduresStepsV2[x].Symptoms[y]]
				} else {
					symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title].push(proceduresModule.proceduresStepsV2[x].Symptoms[y])
				}
			}

		}

		var selectedCauseIndexes: any[] = []
		// Causes
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Causes.length; y++) {
			if (selectedSymptomIndexes.includes(x) || selectedSymptomIndexes.length == 0) {
				if (this.causes.includes(proceduresModule.proceduresStepsV2[x].Causes[y].text)) {
					selectedCauseIndexes.push(x)
				}
				if (causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title] == null) {
					causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title] = [proceduresModule.proceduresStepsV2[x].Causes[y]]
				} else {
					causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title].push(proceduresModule.proceduresStepsV2[x].Causes[y])
				}
			}

		}

		var selectedActionIndexes: any[] = []
		// Actions
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Actions.length; y++) {
			if (selectedCauseIndexes.includes(x) || selectedCauseIndexes.length == 0) {
				if (this.actions.includes(proceduresModule.proceduresStepsV2[x].Actions[y].text)) {
					selectedActionIndexes.push(x)
				}
				if (actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title] == null) {
					actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title] = [proceduresModule.proceduresStepsV2[x].Actions[y]]
				} else {
					actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title].push(proceduresModule.proceduresStepsV2[x].Actions[y])
				}
			}

		}
	}


	var other: any = null
	for (const [key, value] of Object.entries(systemGroupedByTitle)) {



		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				systems: new_other_array
			}
		} else {
			this.systemOptionsSelected.push({
				// @ts-ignore
				title: key,
				// @ts-ignore
				systems: value
			})
		}

	}
	if (other != null) {

		// @ts-ignore
		this.systemOptionsSelected.push(other)
	}



	other = null
	for (const [key, value] of Object.entries(symptomsGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				symptoms: new_other_array
			}
		} else {
			this.symptomOptionsSelected.push({
				// @ts-ignore
				title: key,
				// @ts-ignore
				symptoms: value
			})
		}
	}
	if (other != null) {
		// @ts-ignore
		this.symptomOptionsSelected.push(other)
	}


	other = null
	for (const [key, value] of Object.entries(causesGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				causes: new_other_array
			}
		} else {
			this.causeOptionsSelected.push({
				// @ts-ignore
				title: key,
				// @ts-ignore
				causes: value
			})
		}

	}
	if (other != null) {
		// @ts-ignore
		this.causeOptionsSelected.push(other)
	}

	other = null
	for (const [key, value] of Object.entries(actionsGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				actions: new_other_array
			}
		} else {
			this.actionOptionsSelected.push({
				// @ts-ignore
				title: key,
				// @ts-ignore
				actions: value
			})
		}

	}
	if (other != null) {
		// @ts-ignore
		this.actionOptionsSelected.push(other)
	}

	// this.systemOptionsSelected = []
	// this.symptomOptionsSelected = []
	// this.causeOptionsSelected = []
	// this.actionOptionsSelected = []




//   symptomOptionsSelected = [
// 			  {
// 				title: "Electrical Fault",
// 				symptoms: [
// 					{
// 						name: "Engine Sensor / Control",
// 						selected: false
// 					},{




  }

  deleteSelectedReason(reasonValue: any = '') {
    const value = reasonValue;
    // @ts-ignore
    const index = this.reason.indexOf(value);
    if (index > -1) {
      // @ts-ignore
      this.reason.splice(index, 1);
    }
  }

  deleteSelectedAction(actionValue: any = '') {
    const value = actionValue;
    // @ts-ignore
    const index = this.jobAction.indexOf(value);
    if (index > -1) {
      // @ts-ignore
      this.jobAction.splice(index, 1);
    }
  }

  async setupSetpointPage() {
	  this.isLoadingSetpointData = true;
	  this.chartLoaded = false;
    await this.changeChartTime();

    const { last_7 } = setpointV2Module.tasqMCFData;
    const projected_3 = setpointV2Module.tasqMCFData.best_3;

    const lift_pressure_current = setpointV2Module.tasqListPressureData.Current;
    const lift_pressure_best = setpointV2Module.tasqListPressureData.Best;

    const trying: any[] = [];
    for (let x = 0; x < lift_pressure_current.length; x++) {
      const d = new Date();
 		d.setDate(d.getDate() - x);
      trying.push(d.toLocaleString());
    }

    this.chartCurrentData = {
      labels: trying,
      datasets: [{
        label: 'Current',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: lift_pressure_current,
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      }, {
        label: 'Optimized',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: lift_pressure_best,
        borderColor: '#e66d2c',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(230, 109, 44,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      },
      ],
    };

    this.chartData = {
      labels: ['2021-08-21T13:05', '2021-08-21T13:10', '2021-08-21T13:15', '2021-08-21T13:20', '2021-08-21T13:25', '2021-08-21T13:30', '2021-08-21T13:35', '2021-08-21T13:40', '2021-08-21T13:45'],
      datasets: [{
        label: '',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: last_7,
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      }, {
        label: '',
        // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
        data: [null, null, null, null, null, null, ...projected_3],
        borderColor: '#2CE6C2',
        borderWidth: 1.5,
        pointBackgroundColor: '#0076FF',
        pointBorderColor: '#0076FF',
        pointRadius: 0,
        backgroundColor: 'rgba(44,230,194,0.1)',
        fill: true,
        showLine: true,
        tension: 0,
        spanGaps: true,
        pointHitRadius: 5,
        pointHoverRadius: 0,
      },
      ],
    };

    this.chartLoaded = true;
    this.isLoadingSetpointData = false;
  }

  async changeChartTime(value = 14) {
	  await setpointV2Module.getSetpointRecV2ForTasq({ wellName: this.tasq.wellName });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await this.sleep(0.1);
    this.defermentLabelingModal = true;
  }



  async handleStepOne(selectionAction) {

	  this.selectionAction = selectionAction.value

	  this.isSavingProcedureStep = true
	  this.feedbackSteps[1].active = true
	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		validate: this.selectionAction,
		validateComment: null,
		systems: this.systems,
		systemsComment: this.systemsComment,
		symptoms: this.symptoms,
		symptomsComment: this.symptomsComment,
		causes: this.causes,
		causesComment: this.causesComment,
		actions: this.actions,
		actionsComment: this.actionsComment,
		proceduresJSONVersion: this.jsonVersion,
      });

	this.isSavingProcedureStep = false
	this.setupPage()
	


	//   if (this.tasq.isManuallyAdded) {
	// 	  this.selectionAction = 'YES';
	// 	  // this.leaveFeedbackV2(true, true, true);
	// 	  // return;
	//   }
    // if (!this.selectionAction) {
    //   this.stepOneVariant = 'error';
    //   return;
    // }
    // if (this.selectionAction === 'WAIT_AND_SEE') {
    //   // this.$store.dispatch(ADD_TO_WATCH_LIST, {
    //   //   tasq: this.tasq,
    //   // });
    //   this.$router.push({
    //     name: 'Tasqs',
    //   });
    //   return;
    // }
    // if (this.selectionAction === 'NO') {
    //   if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE') && !omitDefermentLabeling && !this.isBatchResponding) {
    //     // this.$dialog.show('defermentLabelingModal');
    //     this.defermentLabelingModal = true;
    //     return;
    //   }else {
    //     this.defermentLabelingModal = false;
    //   }
    //   this.leaveFeedbackV2(true, true, true);
    //   return;
    // }
    // tasqFeedbackModule.leaveFeedbackV2({
    //   tasq: this.tasq,
    //   selection: this.selectionAction,
    // });
    // this.stepper = 2;
    // this.feedbackSteps[1].active = true;
  }

  async handleStepTwo() {
	var cleaned_systems: any[] = []

	this.feedbackSteps[2].active = true
	this.isSavingProcedureStep = true
	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		validate: this.selectionAction,
		validateComment: null,
		systems: this.systems,
		systemsComment: this.systemsComment,
		symptoms: this.symptoms,
		symptomsComment: this.symptomsComment,
		causes: this.causes,
		causesComment: this.causesComment,
		actions: this.actions,
		actionsComment: this.actionsComment,
		proceduresJSONVersion: this.jsonVersion,
      });

	this.isSavingProcedureStep = false
	  this.setupPage()

    // if (!this.reason.length) {
    //   this.stepTwoVariant = 'error';
    //   return;
    // }
    // if (this.predictionAdditionalAction === 'Failure did not occur') {
    //   this.selectionAction = this.selectionActions[1].value; // NO
    //   this.leaveFeedbackV2(true, true);
    //   return;
    // }
    // if (this.predictionAdditionalAction === 'Validate failure now') {
    //   this.selectionAction = this.selectionActions[0].value; // YES
    // }
    // if (this.predictionAdditionalAction === 'Failure occurred previous to prediction') {
    //   this.selectionAction = this.selectionActions[1].value; // NO
    // }
    // tasqFeedbackModule.leaveFeedbackV2({
    //   tasq: this.tasq,
    //   selection: this.selectionAction,
	//   // @ts-ignore
    //   reason: this.reason,
    //   comment: this.comment,
	//   shouldPostJobComment: true,
    // });
    // this.stepper = 3;
    // this.feedbackSteps[2].active = true;
  }

  async handleStepThree() {
	  this.isSavingProcedureStep = true
	  this.feedbackSteps[3].active = true
	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		validate: this.selectionAction,
		validateComment: null,
		systems: this.systems,
		systemsComment: this.systemsComment,
		symptoms: this.symptoms,
		symptomsComment: this.symptomsComment,
		causes: this.causes,
		causesComment: this.causesComment,
		actions: this.actions,
		actionsComment: this.actionsComment,
		proceduresJSONVersion: this.jsonVersion,
      });
	this.isSavingProcedureStep = false
	  this.setupPage()

    // tasqFeedbackModule.leaveFeedbackV2({
    //   tasq: this.tasq,
    //   selection: this.selectionAction,
	//   // @ts-ignore
    //   reason: this.reason,
    //   correctedStateChangeDate: this.datetime || '',
    //   comment: this.comment,
    //   jobAction: this.jobAction,
    //   markAsComplete: true,
    // });
    // this.stepper = 4;
  }

  async handleStepFour() {
	  this.isSavingProcedureStep = true
	  this.feedbackSteps[4].active = true
	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		validate: this.selectionAction,
		validateComment: null,
		systems: this.systems,
		systemsComment: this.systemsComment,
		symptoms: this.symptoms,
		symptomsComment: this.symptomsComment,
		causes: this.causes,
		causesComment: this.causesComment,
		actions: this.actions,
		actionsComment: this.actionsComment,
		proceduresJSONVersion: this.jsonVersion,
      });

	this.isSavingProcedureStep = false
	  this.setupPage()


    // if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
    //   this.stepper = 4;
    //   return;
    // }
    // this.leaveFeedbackV2(true, true);
  }


  async handleStepFive() {
	//   	validateComment = null,
	// systems,
	// systemsComment = null,
	// symptoms,
	// symptomsComment = null,
	// causes,
	// causesComment = null,
	// actions,
	// actionsComment = null,
	  this.isSavingProcedureStep = true
	//   this.feedbackSteps[1].active = true
	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		validate: this.selectionAction,
		validateComment: null,
		systems: this.systems,
		systemsComment: this.systemsComment,
		symptoms: this.symptoms,
		symptomsComment: this.symptomsComment,
		causes: this.causes,
		causesComment: this.causesComment,
		actions: this.actions,
		actionsComment: this.actionsComment,
		proceduresJSONVersion: this.jsonVersion,
		markAsComplete: true
      });

	this.isSavingProcedureStep = false
	  this.setupPage()
        this.$router.push({ name: 'Tasqs' });
        await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
        // this.stopDataLoading();
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');


    // if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
    //   this.stepper = 4;
    //   return;
    // }
    // this.leaveFeedbackV2(true, true);
  }



  // eslint-disable-next-line consistent-return
  handlePossibleActionsChange(value) {
    if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
      return value;
    }
    // this.getFoundProcedure();
  }

  handleStepClick(step) {

    if (!step.active) return;
    this.stepper = step.id;
  }

  handleTasqChange() {
    this.startDataLoading();
    this.resetFeedback();


// 	if (this.tasqResponseData.)


    if (this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.selectionActions = [{
        text: 'Updated setpoint',
        value: 'YES',
		style: 'FULL'
      }, {
        text: 'Not now',
        value: 'NO',
		style: 'Full'
      }];
    } else if (this.tasq.engineerType === this.$getConst('TASQ_WELL_TEST_TYPE')) {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
		  style: 'FULL'
        },
        {
          text: 'No',
          value: 'NO',
		  style: 'FULL'
        },
      ];
    } else if (this.tasq.isManuallyAdded) {
      this.selectionActions = [];
    } else if (this.tasq.completed) {
      this.selectionActions = [];
    } else {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
		  style: 'FULL'
        },
        {
          text: 'No',
          value: 'NO',
		  style: 'FULL'
        },
        {
          text: 'Not sure',
          value: 'DONT_KNOW',
		  style: 'SUB'
        },
      ];
    }
    this.stopDataLoading();
  }

  resetFeedback() {
    this.stepper = 1;
    this.selectionAction = '';
    this.datetime = '';
    this.jobAction = [];
    this.comment = '';
    this.reason = [];
    this.showComment = false;
    this.showActionComment = false;
  }

  @Debounce(1000)
  async leaveFeedback(
    skipDateTime = false,
    markAsComplete = false,
    shouldPostJobComment = false,
    completeForToday = false,
  ) {
    // @ts-ignore
    if (this.jobAction.find((j) => j.includes('Reassign'))) {
      // eslint-disable-next-line no-param-reassign
      markAsComplete = false;
      this.$emit('reassign-tasq');
    }
    if (this.datetime || skipDateTime) {
      this.startDataLoading();


	await tasqFeedbackModule.leaveFeedbackV2({
        tasq: this.tasq,
		// @ts-ignore
        comment: this.comment,
		validate: '',
		systems: [],
		symptoms: [],
		causes: [],
		actions: [],
		proceduresJSONVersion: "",
		markAsComplete: markAsComplete,
		completedForToday: completeForToday,
		shouldPostAsGlobalWellComment: shouldPostJobComment
      });
      // @ts-ignore
       if (!this.jobAction.find((j) => j.includes('Reassign'))) {
        if(tasqsListModule.activePage === 'Kanban'){
          tasqsListModule.setKanbanSelectedTasq('')
          this.$router.push({ name: 'TasqsKanban' });
        }else if(tasqsListModule.activePage === 'Operations'){
          tasqsListModule.setKanbanSelectedTasq('')
          this.$router.push({ name: 'Operations' });
        }
        else {
          this.$router.push({ name: 'Tasqs' });
          this.closeBatchResponseFeedbackSidebar()
        }
         await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
       }
        this.stopDataLoading();
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');
    }
  }

  toggleShowActivity(activity) {
    this.activityShown = activity;
  }

  showEventOnChart(event) {
    return event;
  }

  openStepDetails(step) {
    (this.foundProcedure as any).steps.forEach((i) => {
      if (step.id !== i.id) {
        Object.assign(i, { showDetails: false });
      }
    });
    Object.assign(step, { showDetails: !step.showDetails });
  }

  updateTasqTime() {
    this.$dialog.show('productionDataModal');
  }

  @Watch('tasq.id', {
    immediate: true,
  })
  onTasqChange() {
    this.handleTasqChange();
  }

  @Watch('jobAction')
  onJobActionChange(value){
    if(value.find((j) => j.includes('Other'))){
      this.showActionComment = true;
    }
  }

  @Watch('showComment')
  onShowComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('showActionComment')
  onShowActionComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('tasqResponseData', {
    immediate: true,
  })
  onTasqResponseChange() {
    this.handleTasqChange();
  }
}
